<!-- src/views/HomePage.vue -->
<template>
  <div class="home">
    <div class="main-info">
      <h1>PLM-FRP Webserver</h1>
      <p>
        Utilize our deep learning model for accurate prediction of Ferroptosis-related proteins (FRPs) and gain insights into their functions and mechanisms.
      </p>
      <p>
        This webserver provides tools for single amino acid sequence prediction and FASTA file prediction.
      </p>
      <div class="warning">
        Note: It is recommended to access this website using HTTP. Accessing via HTTPS may cause issues with file uploads and other functionalities.
      </div>
    </div>
    <div class="links">
      <router-link to="/single-sequence" class="link-button">Single Amino Acid Sequence Prediction</router-link>
      <router-link to="/upload-fasta" class="link-button">Upload FASTA File Prediction</router-link>
    </div>
    <div class="additional-info">
      <h2>Importance of Ferroptosis-Related Proteins</h2>
      <p>
        Ferroptosis, a recently discovered form of regulated cell death characterized by iron-dependent lipid peroxidation, has emerged as a critical process in various pathologies, including cancer, neurodegenerative disorders, and ischemia-reperfusion injury. The concept of "ferroptosis" was first formally proposed by Dixon et al. in 2012, and they conducted in-depth investigations into its mechanisms. Their study revealed that erastin, a ferroptosis inducer, can inhibit system Xc-, leading to the depletion of intracellular GSH, subsequent inactivation of GPX4, and ultimately culminating in lipid ROS accumulation and ferroptosis. This groundbreaking discovery laid the foundation for ferroptosis research and provided crucial insights for the development of novel ferroptosis regulators.
      </p>
      <p>
        Distinct from apoptosis and necrosis, this unique cell death pathway is driven by the accumulation of lipid peroxides, orchestrated by a complex network of proteins known as ferroptosis-related proteins (FRPs). These proteins play diverse roles in initiating, executing, or inhibiting ferroptosis, with key regulators like GPX4, ACSL4, and iron metabolism-related proteins, including ferritin and transferrin receptor 1, emerging as central players in this intricate process. Identifying and characterizing FRPs is paramount for unraveling the molecular mechanisms underlying ferroptosis, enabling the development of targeted therapeutic interventions, and ultimately improving patient outcomes.
      </p>
      <p>
        To ensure prediction accuracy and save computational resources, if the amino acid sequence has been previously trained or computed, the corresponding record will be directly retrieved from the database. Otherwise, a new computation will be performed to obtain the result.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style scoped>
.home {
  text-align: center;
  padding: 20px;
  background-size: cover;
  background-color: #fffdca;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 50px auto;
  color: #e05d5d;
}

.warning {
  color: red;
  font-weight: bold;
  margin-top: 20px;
}

h1 {
  color: #fff;
  font-size: 2.5em;
  margin-bottom: 20px;
}

p {
  color: #ddd;
  font-size: 1.2em;
  margin-bottom: 30px;
}

.links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.link-button {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.link-button:hover {
  background-color: #0056b3;
}

.main-info {
  margin-top: 40px;
  background-color: rgba(59, 0, 0, 0);
  padding: 10px;
  border-radius: 10px;
}

.main-info h1 {
  color: #5faa48;
  font-size: 2em;
  margin-bottom: 20px;
}

.main-info p {
  color: #61004f;
  font-size: 1.2em;
}

.additional-info {
  margin-top: 40px;
  background-color: rgba(0, 0, 0, 0);
  padding: 20px;
  border-radius: 10px;
}

.additional-info h2 {
  color: #000000;
  font-size: 2em;
  margin-bottom: 20px;
}

.additional-info p {
  color: #222222;
  font-size: 1.2em;
}
</style>